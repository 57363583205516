window.addEventListener('load', () => {
    try {
        const herosearchBox = document?.querySelector('.herosearch__box');
        if (herosearchBox) {
            const minusValue = 16;
            const mediaQuery = window.matchMedia('(max-width: 639.98px)');
            if (mediaQuery.matches) {
                herosearchBox?.addEventListener('focusin', () => {
                    if (herosearchBox?.contains(document?.activeElement)) {
                        // Get the bounding rectangle when the input is focused
                        const herosearchBoxGetBounding = herosearchBox.getBoundingClientRect();
                        const herosearchBoxTop = herosearchBoxGetBounding.top + window.scrollY;
                        window.scrollTo({
                            top: herosearchBoxTop - minusValue,
                            behavior: 'smooth'
                        });
                    }
                });
                herosearchBox?.addEventListener('focusout', () => {
                    // Reset to the original scroll position when focus is lost
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                });
            }
        }
    } catch (error) {
        console.error('An error occurred while initializing the Hero Search wrapper:', error);
    }
});
